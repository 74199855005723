
let DataPage = {
    "upload_site" : "",
    "access_dash" : [],

    "name_menu" : [],
    "site"      : [],
    "product"  : [],

    "panel"         : [],
    "panel_data"    : [],
    "list_coverage" : [],

    "broadcast"   : [],

    "entry"   : [],
    "exit"    : [],
    "balance" : [],

    "list_payment" : [],
    "list_bank"    : [],
    "list_origin"  : [],
};

let NotifyDataPage = {
    "panel"              : [],
    "name_menu"          : [],
    "panel_data"         : [],
    "upload_site"        : [],
    "access_dash"        : [],
    "list_coverage"      : [],
    "panel_neighborhood" : []
}

export function SetListData(value) {
    DataPage = value;

    NotifyDataPage["panel"].forEach(element => {               element(value["panel"]); });
    NotifyDataPage["name_menu"].forEach(element => {           element(value["name_menu"]); });
    NotifyDataPage["panel_data"].forEach(element => {          element(value["panel_data"]); });
    NotifyDataPage["upload_site"].forEach(element => {         element(value["upload_site"]); });
    NotifyDataPage["access_dash"].forEach(element => {         element(value["access_dash"]); });
    NotifyDataPage["list_coverage"].forEach(element => {       element(value["list_coverage"]); });
    NotifyDataPage["panel_neighborhood"].forEach(element => {  element(value["panel_neighborhood"]); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
