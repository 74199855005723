import { useState, useEffect } from "react";

import './Panel.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function Page_Panel(props){
    
    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('panel'));

    const [ checked, setChecked ] = useState(CheckedInf());

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    function CheckedInf(){
        if(GetUserData('access') == 1){
            return true;
        }else {
            const newData = GetUserData('page').find(item => item.page == 'Painel');
            if(newData.type == 'Editar'){        
                return true;
            }else {
                return false;
            }
        }
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('panel').forEach(item =>{
                if(item.idData.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.sigla.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.city.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.state.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.address.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('panel'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'panel_details')
        SetListPag('currentPageId', idPage);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "panel", "type" : "delete_panel", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('panel', setShowPageData);
    }, []);

    return(
        <div className="Page_Panel">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="pesquisar..." />
                </div>
                <div className="title_page">Painel</div>
                <div className="list_opt_alt_page">
                    {
                        checked ? 
                        <div className="div_add_new_data" onClick={ ()=>{ PageClick(0) } }>
                            <div className="new_data_add">
                                <SvgAddNewData color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">painel</div>
                        </div> : null
                    }
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="40">ID</th>
                            <th>Cidade</th>
                            <th>Endereço</th>
                            <th width="60">Imagem</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.idData }</td>
                                        <td>{ key.city }</td>
                                        <td>{ key.address }</td>
                                        <td align="center">
                                            {
                                                key.file !='' ?
                                                <img data-tooltip-id={ "file_" + key.id } data-tooltip-content="Visualizar foto" data-tooltip-place="top" alt="Panel" src={ "./assets/panel/" + key.file } className="icon_file" onClick={ ()=>{ OpenFile('showImg', "panel/" + key.file) } } loading="lazy" /> : null
                                            }
                                            <Tooltip id={ "file_" + key.id } />                                            
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    checked ?
                                                    <div data-tooltip-id={ "del_" + key.id } data-tooltip-content="Deletar painel" data-tooltip-place="top" onClick={ ()=>{ DeleteData(key.id, key.idData) } } title="Deletar painel">
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div> : null
                                                }
                                                <Tooltip id={ "del_" + key.id } />
                                                <div data-tooltip-id={ "edi_" + key.id } data-tooltip-content="Editar painel" data-tooltip-place="top" onClick={ ()=>{ PageClick(key.id) } } title="Editar painel">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                                <Tooltip id={ "edi_" + key.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showPageData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}