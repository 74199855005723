import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { GetDataPage } from 'interface/Data';
import { SetModalData, SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgCalendar, SvgDashboard, SvgDashboardExit, SvgJsonUpdate, SvgMenuHamburguer, SvgMenuPoint, SvgPanels, SvgSite, SvgUser, SvgUserDash } from 'components/SvgFile';

import Page_NameSite from 'components/Page/NameSite';

import Page_Users from 'components/Page/Users';
import Page_Users_Details from 'components/Page/Users/Details';

import Page_Panel from 'components/Page/Panel';
import Page_Panel_Details from 'components/Page/Panel/Details';

export default function Contents(props){

    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ status, setStatus ] = useState(false);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        if(document.body.clientWidth <= 640){
            setStatus(false);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 640){
                setStatus(false);
            }
        });
    }

    function CurrentPage(){
        window.history.pushState("", "", "/");
        switch(currentPage) {
            case "access_dash":
                return <Page_Users CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "access_dash_details":
                return <Page_Users_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "menu_site":
                return <Page_NameSite CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            case "panel":
                return <Page_Panel CkickPage={ CkickPage } setLoading={ props.setLoading } />;
            case "panel_details":
                return <Page_Panel_Details CkickPage={ CkickPage } setLoading={ props.setLoading } />;

            default:
                return '';
                // return <QtdData CkickPage={ CkickPage } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        window.history.pushState("", "", "/");
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    function UpdateJson(){
        SetModalData('Confirmation', { "type": "update_json", "setLoading": props.setLoading })
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    return(
        <div className="Contents">
            {/* 
            <a href="https://preview.maxmidiaooh.com.br/" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a> 
            */}

            <div className={ status ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">

                    <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                        <div className={ status ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                            <div className="">
                                <img alt="logotipo" src="./assets/logo_1.png" className={ status ? "logotipo2" : "logotipo" } />
                            </div>
                            <div className={ status ? "icon_menu icon_menu_active" : "icon_menu" }>
                                <SvgMenuHamburguer color="#ffffff" className="icons" />
                            </div>
                        </div>                    
                    </div>
                    
                    <div className={ status ? "show_data_top" : "show_data_top show_data_top_close" }>
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Dashboard</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("access_dash"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgUserDash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Usuários</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "menu_site" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("menu_site"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMenuHamburguer color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Menu do site</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "panel" || currentPage == "panel_details" ? "show_menu_dash active_dash" : status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("panel"); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgPanels color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close" : "name_page" }>Painel</div>
                                        </div>
                                    </div>

                                    <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ UpdateJson(); } }>
                                        <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgJsonUpdate color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status ? "name_page menu_close inf_icon_attention" : "name_page inf_icon_attention" }>
                                                Atualizar site
                                                {
                                                    GetDataPage('upload_site') ? <span className="attention" /> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu = '';
                                            let namePage = '';
                                            switch (key.page) {
                                                case 'Painel':
                                                        namePage = 'panel';
                                                        iconMenu = <SvgPanels color="#FFC400" className="icons_dash" />;
                                                    break;
                                            }
                                            
                                            let activeMenu = '';if(key.name == 'Painel' && currentPage == 'panel' || key.name == 'Painel' && currentPage == 'panel_details'){
                                                status ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else {
                                                status ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                            }
                                            
                                            return(
                                                <div className={ activeMenu } onClick={ ()=>{ CkickPage(namePage); } } key={ index }>
                                                    <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                                        <div className="div_icons">
                                                            { iconMenu }
                                                        </div>
                                                        <div className={ status ? "name_page menu_close" : "name_page" }>
                                                            { key.name }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }

                            <div className={ status ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status ? "name_page menu_close" : "name_page" }>Sair</div>
                                </div>
                            </div>
                        </div>
                        <div className="div_profile">
                            <div className={ status ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                                <div className="div_img">
                                    {
                                        userFile !='' ?
                                        <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                        <SvgUser color="#FFFFFF" className="show_img_profile" />
                                    }
                                </div>
                                <div className={ status ? "name_user menu_close" : "name_user" }>
                                    { userName }
                                </div>
                                <div className={ status ? "open_profile menu_close" : "open_profile" }>
                                    <SvgMenuPoint color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
        </div>
    )
}
