import { useState, useEffect } from "react";

import './NameSite.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { SvgSave, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { Reg_AltPosition, Reg_EditMenu, Reg_StatusMenu } from "services/RegisterData";

export default function Page_NameSite(props){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('name_menu'));  

    function HandleData(type, index, value){
        const newData        = [...dataPage];
        newData[index][type] = value;
        setDataPage(newData);
    }

    function ChangePosition(id, order, type){
        props.setLoading(true);
        Reg_AltPosition('name_menu', id, order, type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function AltStatus(id, status){
        props.setLoading(true);
        Reg_StatusMenu(id, status, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_EditMenu(userId, dataPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('name_menu', setDataPage);
    }, []);  

    return(
        <div className="Page_NameSite">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="title_page">Menu do site</div>
                    <div className="" />
                    <div className="list_opt_alt_page">
                        <button className="div_add_new_data">
                            <div className="new_data_add">
                                <SvgSave color="#ffffff" className="icons" />
                            </div>
                            <div className="name_btn_add">salvar</div>
                        </button>
                    </div>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista dos menus do site</div>
                    </div>
                    <div className="list_input_data">
                        <table width="100%" cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <th width="20" align="center">#</th>
                                    <th>Nome</th>
                                    <th width="60">Status</th>
                                    <th width="60" className="td_order" align="right">Ordem</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {
                                    dataPage.map((key, index)=>{
                                        return(      
                                            <tr key={ index }>
                                                <td align="center">{ index + 1 }</td>
                                                <td>
                                                    <div className="">
                                                        <input type="text" className="div_name_menu space_div" onChange={ (e)=>{ HandleData('name', index, e.target.value) } } value={ key.name } maxLength="30" required />
                                                    </div>
                                                    {
                                                        key.id != 8 ? null : 
                                                        <div className="data_link">
                                                            Link
                                                            <input type="text" className="div_name_menu space_div" onChange={ (e)=>{ HandleData('page', index, e.target.value) } } value={ key.page } required />
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        key.status == 0 ? 
                                                        <div className="status_name disabled" onClick={ ()=>{ AltStatus(key.id, 1) } }>Desativado</div> 
                                                        : 
                                                        <div className="status_name active" onClick={ ()=>{ AltStatus(key.id, 0) } }>Ativado</div>
                                                    }
                                                </td>
                                                <td className="td_order">
                                                    <div className="div_opt_alt">
                                                        {
                                                            index == 0 ?
                                                            <div className="space_div_icon" /> :
                                                            <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'up') } }>
                                                                <SvgSetaUp color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                        {
                                                            (index + 1) == dataPage.length ?
                                                            <div className="space_div_icon" /> :
                                                            <div data-tooltip-id={ "position_" + key.id } data-tooltip-content="Alterar posição" data-tooltip-place="top" className="space_div_icon" onClick={ ()=>{ ChangePosition(key.id, key.order_, 'down') } }>
                                                                <SvgSetaDown color="#324d6b" className="icons"/>
                                                            </div>
                                                        }
                                                        <Tooltip id={ "position_" + key.id } />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
    )
}