import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Reactivate data
export function Reg_Reactivate(type, origin, idData, userId, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', type);

    data.append('id', idData);
    data.append('userId', userId);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Alt position contents
export function Reg_AltPosition(type_page, idPage, order, type, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append('type_page', type_page);
    data.append('edit_data', 'alt_position');

    data.append('idPage', idPage);
    data.append('type', type);
    data.append('order', order);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle(type_page, response.data[type_page]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}
// Update json
export function Reg_UpdateJson(CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', "update_json");

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle("upload_site", response.data.upload_site);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data    = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                    CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}


// Edit or register data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, page, CallbackSuccess, CallbackError, CallbackEmail){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    page.forEach((element, index) => {
        data.append('page_id[]', element.id);
        data.append('page_page[]', element.page);
        data.append('page_type[]', element.type);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        if(response.data.typeRegister == 'ok'){
            SetListDataSingle('access_dash', response.data.access_dash);
            if(response.data.idPage !=0){
                SetListPag('currentPageId', response.data.idPage);
            }

            if(userId == idPage){
                const newData = response.data.access_dash.find(item => item.id == userId);
                cookies.set(typeCookieEmail, email, '/', cookiesRegister);
                if(pass !=''){
                    cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
                }

                SetUserData('id', newData['id']);
                SetUserData('file', newData['file']);
                SetUserData('name', newData['name']);
                SetUserData('email', newData['email']);
                SetUserData('access', newData['access']);
                SetUserData('page', newData['page']);
            }

            CallbackSuccess();

        }else if(response.data.typeRegister == 'erro'){
            CallbackEmail();
        }

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('access_dash', response.data.access_dash);
        const newData = response.data.access_dash.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// New password
export function Reg_NewPass(password, code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'registe_new_pass');

    data.append('password', password);
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        if(response.data == 'conectado'){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}
// Checked code
export function Reg_CodeNewPass(code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'registe_checked');
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + '/php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        if(response.data == 'conectado'){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit name menu
export function Reg_EditMenu(userId, dataPage, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'name_menu');
    data.append('edit_data', 'edit_or_register');

    dataPage.forEach((element, index) => {
        data.append('id[]', element.id);
        data.append('order_[]', element.order_);
        data.append('name[]', element.name);
        data.append('page[]', element.page);
        data.append('status[]', element.status);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('name_menu', response.data.name_menu);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    });
}
// Alt status menu
export function Reg_StatusMenu(id, status, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'name_menu');
    data.append('edit_data', 'alt_status');

    data.append('id', id);
    data.append('status', status);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('name_menu', response.data.name_menu);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit site
export function Reg_Site(userId, idPage, title, text, file, linkFacebook, linkInstagram, linkLinkedin, linkWhatsapp, contents, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'site');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('title', title);
    data.append('text', text);
    data.append('file', file);
    
    data.append('linkFacebook', linkFacebook);
    data.append('linkInstagram', linkInstagram);
    data.append('linkLinkedin', linkLinkedin);
    data.append('linkWhatsapp', linkWhatsapp);

    contents.forEach((elem, index) => {
        data.append('contents_id[]', elem.id);
        data.append('contents_title[]', elem.title);
        data.append('contents_subtitle[]', elem.subtitle);
        data.append('contents_type_video[]', elem.type_video);
        data.append('contents_file_' + index, elem.file);
        data.append('contents_video_' + index, elem.video);
        data.append('contents_number[]', elem.number);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('site', response.data.site);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit Products
export function Reg_Product(userId, id, title, text, file, type, newType, positionX, positionY, width, height, showWidth, widthReal, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'product');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('id', id);
    data.append('title', title);
    data.append('text', text);
    data.append('file', file);
    
    data.append('type', type);
    data.append('new_type', newType);

    data.append('positionX', positionX);
    data.append('positionY', positionY);
    data.append('width', width);
    data.append('height', height);
    data.append('showWidth', showWidth);
    data.append('widthReal', widthReal);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('product', response.data.product);
        SetListDataSingle('panel_data', response.data.panel_data);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit Panel
export function Reg_Panel(userId, idPage, idData, premium, file, dateStart, dateEnd, client, newClient, type, newType, format, newFormat, state, city, neighborhood, newHood, address, obsAddress, latitude, longitude, flowGenerators, size, streetView, codeVideo, text, newCvrg, typeFile, positionX, positionY, width, height, showWidth, widthReal, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'panel');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('id_page', idPage);
    data.append('idData', idData);
    data.append('premium', premium);
    data.append('file', file);
    data.append('date_start', dateStart);
    data.append('date_end', dateEnd);
    data.append('client', client);
    data.append('new_client', newClient);
    data.append('type', type);
    data.append('new_type', newType);
    data.append('format', format);
    data.append('new_format', newFormat);
    data.append('state', state);
    data.append('city', city);
    data.append('neighborhood', neighborhood);
    data.append('new_hood', newHood);
    data.append('address', address);
    data.append('obs_address', obsAddress);
    data.append('latitude', latitude);
    data.append('longitude', longitude);
    data.append('flow_generators', flowGenerators);
    data.append('size', size);
    data.append('street_view', streetView);
    data.append('code_video', codeVideo);
    data.append('text', text);
    
    data.append('typeFile', typeFile);
    data.append('positionX', positionX);
    data.append('positionY', positionY);
    data.append('width', width);
    data.append('height', height);
    data.append('showWidth', showWidth);
    data.append('widthReal', widthReal);

    if(newCvrg){
        newCvrg.forEach(element => {
            data.append('newCvrg[]', element.value);
        });
    }

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('panel', response.data.panel);
        SetListDataSingle('panel_data', response.data.panel_data);
        SetListDataSingle('panel_neighborhood', response.data.panel_neighborhood);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Alt img panel
export function Reg_PanelAltPhoto(userId, idPage, file, positionX, positionY, width, height, showWidth, widthReal, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'panel');
    data.append('edit_data', 'alt_photo');

    data.append('id_page', idPage);
    
    data.append('userId', userId);
    data.append('file', file);
    data.append('positionX', positionX);
    data.append('positionY', positionY);
    data.append('width', width);
    data.append('height', height);
    data.append('showWidth', showWidth);
    data.append('widthReal', widthReal);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
           
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('panel', response.data.panel);
        SetListDataSingle('panel_data', response.data.panel_data);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Edit Broadcast
export function Reg_Broadcast(userId, idPage, title, year, listWeek, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('type_page', 'broadcast');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('id_page', idPage);
    data.append('title', title);
    data.append('year', year);

    listWeek.forEach(element => {
        data.append('week[]', element.week);
        data.append('week_id[]', element.id);
        data.append('week_dateStart[]', element.date_start);
        data.append('week_dateEnd[]', element.date_end);
    });    

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
           
        SetListDataSingle("upload_site", response.data.upload_site);
        SetListDataSingle('broadcast', response.data.broadcast);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}